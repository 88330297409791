/* eslint-disable no-useless-escape */
const Constant = {
    REGEX: {
        EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        NUMBER: /[0-9]/,
        NAME: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
    },
    GOOGLEMAPKEY: {
        ApiKey: "AIzaSyALHsxMvrxGXpASVLOgutscQWkz0Fel69A",
      },
};
export default Constant;
