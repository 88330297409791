import React, { useEffect, useState } from "react";
import crossIcon from "../../images/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  GetmyCurrentSubscription,
  GetmyGiveaway,
} from "../../ReduxToolkit/Slices/Subscription/Subscription.action";
import moment from "moment";
import ConformationPopup from "../../components/modal/ConformationPopup";
const MyGiveaways = () => {
  let dispatch = useDispatch();
  let CancelSubscription = () => {
    SetShowConformationModal(true);
  };
  let subscriptiondetails = useSelector((store) => store?.Subscription);
  useEffect(() => {
    dispatch(GetmyCurrentSubscription());
    dispatch(GetmyGiveaway());
  }, []);
  const [ShowConformationModal, SetShowConformationModal] = useState(false);
  const closeConformModal = () => SetShowConformationModal(false);
  return (
    <div>
      {ShowConformationModal && (
        <ConformationPopup
          ShowConformModal={closeConformModal}
          data={subscriptiondetails?.userSubscription}
        />
      )}
      <div className="flex justify-between items-center">
        <h2 className="text-[#0E2F43] font-semibold text-2xl">
          Current Subscription
        </h2>
        {subscriptiondetails?.userSubscription === null ||
        subscriptiondetails?.userSubscription?.is_subscribe == 0 ? (
          ""
        ) : (
          <button
            onClick={CancelSubscription}
            className="flex items-center gap-2 justify-center py-3 font-semibold px-4 rounded-lg text-[#DE0000] bg-white border border-[#DE0000]"
          >
            Cancel
            <img width={"15px"} src={crossIcon} alt="crossIcon" />
          </button>
        )}
      </div>

      {/* {subscriptiondetails?.userSubscription[0]} */}
      {subscriptiondetails?.userSubscription == null ||
      subscriptiondetails?.userSubscription?.is_subscribe == 0 ? (
        <div className="min-h-14 flex justify-center items-center">
          You are not currently subscribed to any plans
        </div>
      ) : (
        <div className="flex justify-between p-6 bg-[#F3F3F3] rounded-3xl mt-8">
          <div>
            <p className="text-[rgba(14,47,67,0.8)] text-lg font-medium">
              Plans
            </p>
            <p className="text-xl font-medium text-[rgba(21,130,180,1)]">
              {subscriptiondetails?.userSubscription?.plans}
            </p>
          </div>
          <div>
            <p className="text-[rgba(14,47,67,0.8)] text-lg font-medium">
              Start Date
            </p>
            <p className="text-lg text-primary-blue font-medium">
              {moment(subscriptiondetails?.userSubscription?.start_date)
                .local()
                .format("MMMM Do YYYY") || "--"}
            </p>
          </div>
          <div>
            <p className="text-[rgba(14,47,67,0.8)] text-lg font-medium">
              Expiry Date
            </p>
            <p className="text-lg text-[rgba(18,104,146,1)] font-medium">
              {moment(subscriptiondetails?.userSubscription?.end_date)
                .local()
                .format("MMMM Do YYYY") || "--"}
            </p>
          </div>
          <div className="max-w-[300px]">
            <p className="text-[rgba(14,47,67,0.8)] text-lg font-medium">
              Details
            </p>
            <ul className="list-disc text-[#6E818D] text-base">
              <li>{subscriptiondetails?.userSubscription?.description}</li>
            </ul>
          </div>
        </div>
      )}

      <div className="mt-6">
        <h2 className="text-[#0E2F43] font-semibold text-2xl">Giveaways</h2>
      </div>
      {subscriptiondetails?.Mygiveaways?.length == 0 ||
      subscriptiondetails?.Mygiveaways == null ? (
        <div className="min-h-14 flex justify-center items-center">
          You are not currently part of any Giveaways !
        </div>
      ) : (
        subscriptiondetails?.Mygiveaways?.map((el) => (
          <div className="flex justify-between p-6 bg-light-blue rounded-3xl mt-8">
            <div>
              <p className="text-[rgba(14,47,67,0.8)] text-lg font-medium">
                Title
              </p>
              <p className="text-xl font-medium text-[rgba(21,130,180,1)]">
                {el?.title}
              </p>
            </div>
            <div>
              <p className="text-[rgba(14,47,67,0.8)] text-lg font-medium">
                Launch Date
              </p>
              <p className="text-lg text-primary-blue font-medium">
                {moment(el?.start_date).local().format("MMMM Do YYYY") || "--"}
              </p>
            </div>
            <div>
              <p className="text-[rgba(14,47,67,0.8)] text-lg font-medium">
                End Date
              </p>
              <p className="text-lg text-primary-blue font-medium">
                {moment(el?.end_date).local().format("MMMM Do YYYY") || "--"}
              </p>
            </div>
            <div className="max-w-[300px] flex flex-col items-center gap-5">
              <button className="py-3 font-semibold px-6 rounded-lg text-white bg-primary-blue border">
                Status
              </button>
              <ul className="text-[#6E818D] text-base text-center">
                <li>
                  You will automatically be entered into current giveaway
                  promotions
                </li>
              </ul>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default MyGiveaways;
