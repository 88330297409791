import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { EffectFade, Pagination, Autoplay } from "swiper/modules";
import { BaseUrl } from "../ReduxToolkit/Apis";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import dummylogo from "../images/Logo.png"
const Slider = ({ data }) => {
  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      ls
      effect={"fade"}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[EffectFade, Autoplay, Pagination]}
      className="mySwiper"
    >
      {data?.map((el) => (
        <SwiperSlide className="">
          <LazyLoadImage effect="blur" height={"100%"} width={"100%"}   src={`${BaseUrl}/${el?.image}`} className="object-cover w-full h-full" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
