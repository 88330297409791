import { createSlice } from "@reduxjs/toolkit";
import { GetGeneralDocsData } from "./ContentManage.action";
let initialState = {
  loading: false,
  error: null,
  GeneralDocs:{},
 
 
};

const GeneralDocsSlice = createSlice({
  name: "generalDocs",
  initialState,
  extraReducers: (builder) => {
    // --------- Get Giveaways  Category ---------
    
      builder
      .addCase(GetGeneralDocsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetGeneralDocsData.fulfilled, (state, action) => {
        state.loading = false;
        state.GeneralDocs = action?.payload?.r;
      })
      .addCase(GetGeneralDocsData.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    
  },
})

export default GeneralDocsSlice.reducer;
