import Homepage from "../Pages/Homepage";
import React from "react";
import { Route, Routes } from "react-router-dom";
import OurPartners from "../Pages/PartnersPages/OurPartners";
import PartnerDetails from "../Pages/PartnersPages/PartnerDetails";
import PartnerWrapper from "../Pages/PartnersPages/PartnerWrapper";
import OfferWrapper from "../Pages/OffersPages/OfferWrapper";
import OffersPage from "../Pages/OffersPages/OffersPage";
import OfferDetails from "../Pages/OffersPages/OfferDetails";
import AllOffers from "../Pages/OffersPages/AllOffers";
import Giveaways from "../Pages/Giveaways";
import ContactUs from "../Pages/ContactUs";
import MyAccount from "../Pages/MyAccounts/MyAccount";
import About from "../Pages/About";
import MyCoupons from "../Pages/MyAccounts/MyCoupons";
import OneOffPlans from "../Pages/MyAccounts/OneOffPlans";
import MyGiveaways from "../Pages/MyAccounts/MyGiveaways";
import PaymentHistory from "../Pages/MyAccounts/PaymentHistory";
import PrivateRouting from "./PrivateRouting";
import Terms from "../Pages/Terms";
import Policy from "../Pages/Policy";
import Checkout from "../Pages/Checkout";

const CombineRouting = () => {
  const myRoutes = [
    {
      path: "/",
      element: <Homepage />,
      childRoutes: [],
    },
    ,
    {
      path: "/aboutus",
      element: <About />,
    },
    {
      path: "/terms-condition",
      element: <Terms />,
    },
    {
      path: "/policy",
      element: <Policy />,
    },
    {
      path: "/checkout",
      element: (
        <PrivateRouting>
          <Checkout />
        </PrivateRouting>
      ),
    },
    {
      path: "/ourpartners",
      element: <PartnerWrapper />,
      childRoutes: [
        { path: "", element: <OurPartners /> },
        { path: ":id", element: <PartnerDetails /> },
      ],
    },
    {
      path: "/offers",
      element: <OfferWrapper />,
      childRoutes: [
        {
          path: "",
          element: <OffersPage />,
          //   childRoutes: [
          //     {
          //       path: "alloffers",
          //       element: <AllOffersWrapper />,
          //       childRoutes: [
          //         { path: "", element: <AllOffers /> },

          //       ],
          //     },
          //   ],
        },
        { path: "alloffers/:id", element: <AllOffers /> },
        { path: ":id", element: <OfferDetails /> },
      ],
    },
    {
      path: "/giveaways",
      element: <Giveaways />,
    },
    {
      path: "/contactus",
      element: <ContactUs />,
    },
    {
      path: "/myaccount",
      element: (
        <PrivateRouting>
          {" "}
          <MyAccount />
        </PrivateRouting>
      ),
      childRoutes: [
        { path: "", element: <MyCoupons /> },
        { path: "oneoffplans", element: <OneOffPlans /> },
        { path: "mygiveaways", element: <MyGiveaways /> },
        { path: "paymenthistory", element: <PaymentHistory /> },
      ],
    },
    {
      path: "/checkout",
      element: (
        <PrivateRouting>
          {" "}
          <Checkout />
        </PrivateRouting>
      ),
    },
  ]
  

  return (
    <Routes>
      {myRoutes.map((firstchild, index) => (
        <Route key={index} path={firstchild.path} element={firstchild.element}>
          {firstchild.childRoutes?.map((secondchild, childIndex) => (
            <Route
              key={childIndex}
              path={secondchild.path}
              element={secondchild.element}
            >
              {secondchild.childRoutes?.map((thirdchild, thirdChildIndex) => (
                <Route
                  key={thirdChildIndex}
                  path={thirdchild.path}
                  element={thirdchild.element}
                >
                  {thirdchild.childRoutes?.map(
                    (fourthchild, fourthchildindecx) => (
                      <Route
                        key={fourthchildindecx}
                        path={fourthchild.path}
                        element={fourthchild.element}
                      />
                    )
                  )}
                </Route>
              ))}
            </Route>
          ))}
        </Route>
      ))}
    </Routes>
  );
};

export default CombineRouting;
