import React, { useEffect } from "react";
import Titlebar from "../Titlebar";
import Marque from "../Marque";
import MarqueTwo from "../MarqueTwo";
import { useDispatch, useSelector } from "react-redux";
import { GetAllPartners } from "../../ReduxToolkit/Slices/Partners/Partners.action";
 

const Partners = () => {
  let dispatch=useDispatch()
  useEffect(()=>{
    dispatch(GetAllPartners());
  },[])
  let partners = useSelector((store) => store?.Partners);
  
  return (
    <section className="h-full bg-white w-full py-8">
      <div className="w-[90%] max-w-[1500px] mx-auto">
        <Titlebar title={"Our Partners"} route={"/ourpartners/"} />
        <div className="mt-8">
          <div>
            <Marque data={partners?.AllPartners} loading={partners?.loading} />
          </div>
          <div className="mt-8">
            <MarqueTwo data={partners?.AllPartners}  loading={partners?.loading} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
