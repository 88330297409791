import { createSlice } from "@reduxjs/toolkit";
import { GetAllPartners, GetPartnerById, GetPartnerTermsandConditions } from "./Partners.action";
 
let initialState = {
  loading: false,
  error: null,
  AllPartners: [],
 PartnerDetailsById:{} ,
 PartnerTermsandConditions:{} 
 
};

const PartnerSlice = createSlice({
  name: "partners",
  initialState,
  extraReducers: (builder) => {
    // --------- Get Homepage Category ---------
    builder
      .addCase(GetAllPartners.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllPartners.fulfilled, (state, action) => {
        state.loading = false;
        state.AllPartners = action?.payload?.r;
      })
      .addCase(GetAllPartners.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
   
    // --------- Get Homepage Category ---------
    builder
      .addCase(GetPartnerById.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetPartnerById.fulfilled, (state, action) => {
        state.loading = false;
        state.PartnerDetailsById = action?.payload?.r;
      })
      .addCase(GetPartnerById.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
   
   
    // --------- Get Partners Terms and Condition ---------
    builder
      .addCase(GetPartnerTermsandConditions.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetPartnerTermsandConditions.fulfilled, (state, action) => {
        state.loading = false;
        state.PartnerTermsandConditions = action?.payload?.r;
      })
      .addCase(GetPartnerTermsandConditions.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
   
  },
});

export default PartnerSlice.reducer;
