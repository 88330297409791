import React from "react";
import userIcon from "../images/usericon.png";
import { BaseUrl } from "../ReduxToolkit/Apis";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import usericonplaceholder from "../images/usericon.png";

const Card = ({ data }) => {
  return (
    <>
      <div className="group w-full shadow-[0_2px_8px_rgba(0,0,0,0.1)] cursor-pointer border-2 border-transparent hover:border-primary-blue transition-border duration-300 rounded-md">
        <div className="w-full relative ">
          <div className=" ribbon absolute z-20 top-5 left-0 md:text-[14px] lg:text-[16px] xl:text-[20px] overflow-hidden text-ellipsis whitespace-nowrap">
            {data?.labels}
          </div>
          <div className="h-[100%]">
            <LazyLoadImage
              placeholderSrc={usericonplaceholder}
              effect="blur"
              width={"100%"}
              height={"300px"}
              alt="category image"
              src={
                data?.images[0] !== 0
                  ? `${BaseUrl}${data?.images[0]?.image}`
                  : ""
              }
              className="object-cover rounded-t-md h-[300px] w-full"
            />
          </div>
        </div>
        <div className="p-4 flex flex-col gap-3 ">
          <h3 className=" group-hover:font-bold text-dark-blue text-cmd md:text-c_md font-semibold text-nowrap transition-all duration-300 overflow-hidden text-ellipsis whitespace-nowrap">
            {data?.title}
          </h3>
          <div>
            <p className="text-[#23A1D4] text-c_sm overflow-hidden text-ellipsis whitespace-nowrap">
              {data?.subtitle ? data?.subtitle : "....."}
            </p>
            <p
              className="text-[#6E818D] text-c_sm line-clamp-1"
              dangerouslySetInnerHTML={{
                __html: data?.description ? data?.description : ".....",
              }}
            />
          </div>
          <div className="flex justify-between">
            <div className="flex items-baseline gap-2">
              <span className="text-cx lg:text-c_x font-bold group-hover:text-dark-blue group-hover:scale-105 text-[#23A1D4] transition-all duration-300">
                ${data?.strike_amount ? data?.strike_amount : "0"}
              </span>
              <span className="text-[#6E818D] text-[14px] line-through">
                ${data?.real_amount ? data?.real_amount : 0}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img
                className="w-[30px] lg:w-[40px]"
                src={userIcon}
                alt="user icon"
              />
              <span className="text-[#126892] font-semibold text-[14px] lg:text-[16px]">
                {data?.used_coupon_count ? data?.used_coupon_count : 0} bought
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
