import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "../../Apis";

export const GetHomepageCategory = createAsyncThunk(
  "offers/Homecategorys",
  async (category) => {
    try {
      const url = category 
        ? `${BaseUrl}/api/offer/home-offers?search=${category}`
        : `${BaseUrl}/api/offer/home-offers`;
      let data = await axios.get(url);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const GetHomeBannerImages = createAsyncThunk(
  "offers/GetHomeBannerImages",
  async () => {
    try {
      let data = await axios.get(`${BaseUrl}/api/content/get-banners`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)
export const GetOffersByCategoryid = createAsyncThunk(
  "offers/GetOffersByCategoryid",
  async ({ Category_id, type, searchkey }) => {
    try {
      // Build the URL with the required parameters
      const params = new URLSearchParams({
        type,
        category_id: Category_id,
      });

      // Add searchkey to the params if it's provided
      if (searchkey) {
        params.append("searchkey", searchkey);
      }

      // Make the API call with the constructed URL
      let data = await axios.get(`${BaseUrl}/api/offer/get-offers?${params.toString()}`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetOffersDetailsByCategoryId = createAsyncThunk(
  "offers/GetOffersDetailsByCategoryId",
  async (id) => {
    try {
      let data = await axios.get(`${BaseUrl}/api/offer/get-offer-details?offer_id=${id}`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)
export const GetOffersByPartnerId = createAsyncThunk(
  "offers/GetOffersByPartnerId",
  async (id) => {
    try {
      let data = await axios.get(`${BaseUrl}/api/offer/get-offers?type=2&partner_id=${id}`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)
