import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl, getUserdata } from "../../Apis";



export const Login = createAsyncThunk("auth/login", async (logindata) => {
  try {
    let data = await axios.post(`${BaseUrl}/api/auth/login`, logindata);
    return data.data;
  } catch (error) {
    throw error;
  }
});
export const Signup = createAsyncThunk("auth/Signup", async (Signupdata) => {
  try {
    let data = await axios.post(`${BaseUrl}/api/auth/signup`, Signupdata);
    return data.data;
  } catch (error) {
    throw error;
  }
});
export const ResetPassword= createAsyncThunk("auth/ResetPassword", async (email) => {
  try {
    let data = await axios.post(`${BaseUrl}/api/auth/forgot-password`, email);
    return data.data;
  } catch (error) {
    throw error;
  }
});
export const ChangePassword= createAsyncThunk("auth/ChangePassword", async (passwordDetails) => {
   
  try {
    let { apikey, token } = getUserdata;
    const config = {
      headers: {
        apikey,
        token,
      },
    }
    let data = await axios.post(`${BaseUrl}/api/user/change-password`, passwordDetails , config );
    return data.data;
  } catch (error) {
    throw error;
  }
});


export const GetRegionList = createAsyncThunk("auth/regionlist", async () => {
  try {
    let data = await axios.get(`${BaseUrl}/api/auth/get-region`);
    return data.data;
  } catch (error) {
    throw error;
  }
});

export const Logout = createAsyncThunk("auth/logout", async () => {
  try {
    localStorage.removeItem("SaveOn_Web_Cread");
    return "Logout Sucesss";
  } catch (error) {
    throw error;
  }
});



export const GetUserProfileDetails = createAsyncThunk("auth/GetUserProfileDetails", async (id) => {

  try {
    let { apikey, token } = getUserdata;
    const config = {
      headers: {
        apikey,
        token,
      },
    }
    let data = await axios.get(`${BaseUrl}/api/user/get-user-by-id?user_id=${id}`, config);
    return data.data;
  } catch (error) {
    throw error;
  }

});

export const ContactToUs = createAsyncThunk("auth/ContactToUs", async (ContactUsData) => {
  try {
    let data = await axios.post(`${BaseUrl}/api/auth/contact-us`, ContactUsData);
    return data.data;
  } catch (error) {
    throw error;
  }
});