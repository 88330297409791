import React, { useEffect, useState } from "react";
import CustomDropDown from "../components/CustomDropDown";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { GetRegionList } from "../ReduxToolkit/Slices/Auth/Auth.action";
import { useDispatch, useSelector } from "react-redux";
import paypalLogo from "../images/paypalLogo.png";
import {
  addupdatecheckoutDetails,
  CreateOrderSubscription,
  CreatThisplanOrder,
  GetmyCheckoutDetails,
} from "../ReduxToolkit/Slices/Subscription/Subscription.action";

const Checkout = () => {
  const location = useLocation();
  const planData = location?.state;
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [loadRedirect, setloadRedirect] = useState(false);
  let AuthData = useSelector((store) => store?.Auth);
  let Subscription = useSelector((store) => store?.Subscription);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    region: "",
    region_id: null,
    address: "",
    town: "",
    postcode: "",
    acceptTerms: false,
  });

  useEffect(() => {
    dispatch(GetRegionList());
    dispatch(GetmyCheckoutDetails())
      .unwrap()
      .then((result) => {
        if (result?.s === 1) {
          console.log(result);
          const data = result?.r;
          setInitialValues({
            firstName: data?.f_name || "",
            lastName: data?.l_name || "",
            region: data?.region || "Asia",
            region_id: data?.region_id,
            address: data?.address || "",
            town: data?.town || "",
            postcode: data?.postcode || "",
            acceptTerms: false,
          });
        }
      });
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required!"),
      lastName: Yup.string().required("Last name is required!"),
      region: Yup.string().required("Region is required!"),
      region_id: Yup.number().required("Region is required!"),
      address: Yup.string().required("Address is required!"),
      town: Yup.string().required("Town is required!"),
      postcode: Yup.string().required("Postcode is required!"),
      acceptTerms: Yup.boolean().oneOf(
        [true],
        "Accepting Terms & Conditions is required!"
      ),
    }),
    onSubmit: (values) => {
      let planFormdata = new FormData();
      let checkoutformdata = new FormData();
      checkoutformdata.append("f_name", values?.firstName);
      checkoutformdata.append("l_name", values?.lastName);
      checkoutformdata.append("region_id", values?.region_id);
      checkoutformdata.append("address", values?.address);
      checkoutformdata.append("town", values?.town);
      checkoutformdata.append("postcode", values?.postcode);

      dispatch(addupdatecheckoutDetails(checkoutformdata))
        .unwrap()
        .then((result) => {
          console.log(result);
        });

      if ([1, 2, 3].includes(planData?.planData?.plan_type)) {
        planFormdata.append("plan_id", planData?.planData?.plan_type);
      } else {
        planFormdata.append("type", planData?.planData?.plan_type);
      }
      setloadRedirect(true);
      const action = [1, 2, 3].includes(planData?.planData?.plan_type)
        ? CreatThisplanOrder(planFormdata)
        : CreateOrderSubscription(planFormdata);
      dispatch(action)
        .unwrap()
        .then((result) => {
          console.log("API call result:", result);
          if (result?.s === 1) {
            const approveLink = result?.r?.links?.find(
              (link) => link?.rel === "approve"
            );
            if (approveLink) {
              window.location.href = approveLink?.href;
            } else {
              setloadRedirect(false);
            }
          } else {
            setloadRedirect(false);
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
          setloadRedirect(false);
        });
    },
  });

  const getPlanType = (type) => {
    switch (type) {
      case 1:
        return "Silver";
      case 2:
        return "Gold";
      case 3:
        return "Platinum";
      case 4:
        return "Entry";
      case 5:
        return "Bronze";
      default:
        return "";
    }
  };

  const RedirectToTermsandCondition = () => {
    navigate("/terms-condition");
  };

  const RedirectToPrivacy = () => {
    navigate("/policy");
  };

  return (
    <section className="">
      {loadRedirect && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center  bg-black bg-opacity-50">
          <span className="loader">Redirecting to Payment !! </span>
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div className="flex justify-between items-center">
          <div className="bg-light-blue  py-8 pr-20 w-[50%] border-box h-[90vh] overflow-scroll">
            <h2 className="w-[90%] ml-auto mb-4 font-semibold text-2xl">
              Checkout Details
            </h2>
            <div className="bg-white shadow-[0_2px_8px_0px_rgba(0,0,0,0.24)] p-8 w-[90%] rounded-3xl ml-auto">
              <h3 className="font-semibold text-2xl text-primary-blue">
                Billing Details
              </h3>
              {/* --------- First Name Field --------- */}
              <div className="mt-6 relative">
                <label
                  htmlFor="firstName"
                  className="text-left text-[#082F49D1]"
                >
                  First Name
                </label>
                <input
                  className="border border-gray-300 mt-2 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                  type="text"
                  name="firstName"
                  placeholder="Enter First Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  style={{ background: "rgba(241,249,254,1)" }}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="text-red-600">{formik.errors.firstName}</div>
                )}
              </div>

              {/* --------- Last Name Field --------- */}
              <div className="mt-6 relative">
                <label
                  htmlFor="lastName"
                  className="text-left text-[#082F49D1] mb-2"
                >
                  Last Name
                </label>
                <input
                  className="border border-gray-300 mt-2 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                  type="text"
                  name="lastName"
                  placeholder="Enter Last Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  style={{ background: "rgba(241,249,254,1)" }}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="text-red-600">{formik.errors.lastName}</div>
                )}
              </div>

              {/* --------- Region Field --------- */}
              <div className="mt-6 relative">
                <label
                  htmlFor="region"
                  className="text-left text-[#082F49D1] mb-2"
                >
                  Region
                </label>
                <CustomDropDown
                  value={formik.values.region}
                  onChange={(event, selectedId) => {
                    formik.setFieldValue("region", event.target.value);
                    formik.setFieldValue("region_id", selectedId);
                  }}
                  menuList={AuthData?.RegionList}
                  onBlur={formik.handleBlur}
                  NoData="No region available"
                  placeholder={"Select Region"}
                />
                {formik.touched.region && formik.errors.region && (
                  <div className="text-red-600">{formik.errors.region}</div>
                )}
              </div>

              {/* --------- Address Field --------- */}
              <div className="mt-6 relative">
                <label
                  htmlFor="address"
                  className="text-left text-[#082F49D1] mb-2"
                >
                  Address
                </label>
                <input
                  className="border border-gray-300 mt-2 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                  type="text"
                  name="address"
                  placeholder="Your address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  style={{ background: "rgba(241,249,254,1)" }}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="text-red-600">{formik.errors.address}</div>
                )}
              </div>

              {/* --------- Town Field --------- */}
              <div className="mt-6 relative">
                <label
                  htmlFor="town"
                  className="text-left text-[#082F49D1] mb-2"
                >
                  Town
                </label>
                <input
                  className="border border-gray-300 mt-2 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                  type="text"
                  name="town"
                  placeholder="Enter Town"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.town}
                  style={{ background: "rgba(241,249,254,1)" }}
                />
                {formik.touched.town && formik.errors.town && (
                  <div className="text-red-600">{formik.errors.town}</div>
                )}
              </div>

              {/* --------- Postcode Field --------- */}
              <div className="mt-6 relative">
                <label
                  htmlFor="postcode"
                  className="text-left text-[#082F49D1] mb-2"
                >
                  Postcode
                </label>
                <input
                  className="border border-gray-300 mt-2 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                  type="number"
                  name="postcode"
                  placeholder="Enter Postcode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postcode}
                  style={{ background: "rgba(241,249,254,1)" }}
                />
                {formik.touched.postcode && formik.errors.postcode && (
                  <div className="text-red-600">{formik.errors.postcode}</div>
                )}
              </div>
            </div>
          </div>

          <div className="py-8 w-[50%] border-box h-[90vh] flex justify-center items-center  m-auto overflow-scroll">
            <div className="bg-white shadow-[0_2px_8px_0px_rgba(0,0,0,0.24)]  border-box  p-8 w-[80%] rounded-3xl">
              <h3 className="font-semibold text-2xl text-primary-blue">
                Order Summary
              </h3>
              <div className="flex items-center justify-between text-[rgba(14,47,67,1)] mt-4">
                <span>Plan Type</span>
                <span>{getPlanType(planData?.planData?.plan_type)}</span>
              </div>
              <div className="flex items-center justify-between text-[rgba(14,47,67,1)] mt-4">
                <span>Days</span>
                <span>{planData?.planData?.days} Days</span>
              </div>
              <div className="flex items-center justify-between mt-4">
                <span className="text-primary-blue text-xl font-medium">
                  Subtotal
                </span>
                <span className="text-[rgba(14,47,67,1)] text-2xl font-medium">
                  ${planData?.planData?.amount}
                </span>
              </div>
              <hr className="text-[#6E818D] bg-[#6E818D] my-4" />
              <div className="flex items-center justify-between">
                <span className="text-[rgba(14,47,67,1)] text-xl font-medium">
                  Total
                </span>
                <span className="font-semibold text-4xl text-primary-blue">
                  ${planData?.planData?.amount}
                </span>
              </div>
              {/* --------- Terms and Conditions Checkbox ---------- */}
              <div className="mb-4 mt-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="acceptTerms"
                    checked={formik.values.acceptTerms}
                    onChange={(e) => {
                      formik.setFieldValue("acceptTerms", e.target.checked);
                    }}
                    className="mr-2 cursor-pointer"
                  />
                  <span className="text-[14px] text-black">
                    I Understand the{" "}
                    <span
                      onClick={RedirectToTermsandCondition}
                      className="cursor-pointer text-primary-blue font-semibold"
                    >
                      {" "}
                      Terms of Services{" "}
                    </span>{" "}
                    and{" "}
                    <span
                      onClick={RedirectToPrivacy}
                      className="text-primary-blue font-semibold cursor-pointer"
                    >
                      {" "}
                      Privacy Policy{" "}
                    </span>
                    .
                  </span>
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.acceptTerms}
                  </div>
                )}
              </div>
              <button className="flex items-center justify-center gap-2 bg-light-blue shadow-[2px_2px_0_0_rgba(186,230,253,1)] p-3 w-full rounded-[14px] transition duration-300 ease-in-out hover:scale-[0.9]">
                <span className="text-sm text-primary-blue">pay with</span>
                <img className="w-32" src={paypalLogo} alt="paypal logo" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default Checkout;
