import moment from "moment";
import React, { useEffect, useRef } from "react";
import { RxCrossCircled } from "react-icons/rx";

const ViewmoreModal = ({ showViewMoreModal, data }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        showViewMoreModal(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showViewMoreModal]);

  if (!showViewMoreModal) {
    return null; // Don't render the modal if the state is false
  }
  console.log(data);

  return (
    <div className="fixed z-[105] inset-0 bg-[rgba(8,47,73,0.84)] flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-white rounded-[32px] relative w-[90%] max-w-[950px] tracking-wide"
      >
        <RxCrossCircled
          style={{
            fontSize: "30px",
            top: "-50px",
            right: "0",
            position: "absolute",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={showViewMoreModal}
        />
        <div className="relative overflow-x-auto no-scrollbar p-4 sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className=" text-[rgba(14,47,67,0.8)] capitalize bg-transparent">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Plan
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Start Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  End Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Days
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Available Coupons
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="bg-[rgba(21,74,101,0.06)]">
                <th
                  scope="row"
                  className="px-6 py-3 text-lg sm:text-2xl font-medium text-[rgba(21,130,180,1)] whitespace-nowrap"
                >
                  ${data?.amount}
                </th>
                <td className="px-6 py-3 text-base text-primary-blue font-medium whitespace-nowrap text-nowrap">
                  {data?.plans}
                </td>
                <td className="px-6 py-3 text-base text-[rgba(18,104,146,1)] font-medium whitespace-nowrap text-nowrap">
                  {moment(data?.start_date).local().format("MMMM Do YYYY") ||
                    "--"}
                </td>
                <td className="px-6 py-3 text-base text-[rgba(18,104,146,1)] font-medium whitespace-nowrap text-nowrap">
                  {moment(data?.end_date).local().format("MMMM Do YYYY") ||
                    "--"}
                </td>
                <td className="px-6 py-3">
                  <p className="text-[#1582B4] text-base font-medium">
                    <span>{data?.days}</span> Days
                  </p>
                </td>
                <td className="px-6 py-3 text-center flex justify-center">
                  <div className="p-3 text-center  bg-[rgba(192,229,247,1)] text-base font-semibold rounded-xl w-fit text-primary-blue whitespace-nowrap text-nowrap">
                    {data?.payment_history?.token} Coupons
                  </div>
                </td>
              </tr>
              <tr className="bg-white">
                <td colSpan="6" className="px-6 py-3">
                  <span className="text-nowrap text-lg">Transaction ID</span>
                </td>
              </tr>
              <tr className="bg-[rgba(21,74,101,0.06)]">
                <td colSpan="6" className="px-6 py-3">
                  <span className="text-nowrap text-base font-semibold text-[rgba(21,130,180,1)]">
                    {data?.payment_history?.transaction_id}
                  </span>
                </td>
              </tr>
              <tr className="bg-white">
                <td colSpan="6" className="px-6 py-3">
                  <span className="text-nowrap text-lg">Description</span>
                </td>
              </tr>
              <tr className="bg-[rgba(21,74,101,0.06)]">
                <td colSpan="6" className="px-6 py-3">
                  <p className="text-[#0E2F43] text-base">
                    {data?.payment_history?.description}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewmoreModal;
