import React from "react";
import { Link } from "react-router-dom";

const Titlebar = ({ title, route, data }) => {
 
  return (
    <div className="w-full flex justify-between items-center ">
      <h2 className="font-bold text-cx lg:text-c_x transition-colors duration-300 group-hover:text-blue-700">
        {title}
      </h2>
      {route  ? (
        <Link
          to={`${route}`}
          className="group inline-flex items-center justify-center   py-2 text-[12px]  lg:text-[14px] xl:text-[16px] px-8 md:px-8 md:py-2 xl:px-8 xl:py-3 mr-1 text-center font-semibold text-white no-underline align-middle bg-[#23A1D4] border-2 rounded-md cursor-pointer select-none sm:w-auto hover:bg-[rgba(7, 14, 16, 0.7)] tracking-widest relative overflow-hidden"
        >
          View More
          <svg
            className="absolute right-2 transition-transform duration-300 transform translate-x-0 group-hover:translate-x-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 17L15 12L10 7"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      ) : (
        ""
      )}
    </div>
  );
};

export default Titlebar;
