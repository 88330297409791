import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl, getUserdata } from "../../Apis";

 
export const GetGeneralDocsData = createAsyncThunk(
    "generalDocs/GetGeneralDocsData",
    async () => {
      try {
        let data = await axios.get(`${BaseUrl}/api/content/get-general-docs`);
        return data.data;
      } catch (error) {
        throw error;
      }
    }
  )