import { configureStore } from "@reduxjs/toolkit";
import OfferReducer from "./Slices/Offers/Offers.slice"
import PartnerReducer from "./Slices/Partners/Partners.slice"
import AuthReducer from "./Slices/Auth/Auth.slice"
 import DealsReducer from "./Slices/Deals/Deals.slice"
 import ContentReducer from "./Slices/Contentmanage/ContentManage.slice"
 import SubscriptionReducer from "./Slices/Subscription/Subscription.slice"
export const Store = configureStore({
  reducer: {
    Auth:AuthReducer,
    Offers:OfferReducer,
    Partners:PartnerReducer,
    Deals:DealsReducer,
    Content:ContentReducer, 
    Subscription:SubscriptionReducer
  },
})
