import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import emptyStar from "../../images/starOutline.svg";
import filledStar from "../../images/starFilled.svg";
import offerCard from "../../images/offerCard.png";
import scissor from "../../images/scissorTwo.svg";
import { RxCrossCircled } from "react-icons/rx";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { RateOrder } from "../../ReduxToolkit/Slices/Subscription/Subscription.action";

const CouponModal = ({ closeCouponModal, data }) => {
  const modalRef = useRef(null);
  const [copyStatus, setCopyStatus] = useState("Copy");
  let dispatch = useDispatch();
  let [israted, setisrated] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeCouponModal();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeCouponModal]);

  const ratingChanged = async (newRating) => {
    try {
      let ratingdata = new FormData();
      ratingdata.append("offer_id", data?.offerData?.id);
      ratingdata.append("rating", newRating);
      dispatch(RateOrder(ratingdata))
        .unwrap()
        .then((result) => {
          if (result?.s === 1) {
            setisrated(true);
            toast.success("Thank you for your rating!");
          }
        });
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(data?.coupendata?.code)
      .then(() => {
        setCopyStatus("Copied!");
        toast.success("Copied!");
        setTimeout(() => {
          setCopyStatus("Copy");
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="fixed z-50 inset-0 bg-[rgba(8,47,73,0.84)] flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-[#135779] rounded-[32px] p-10 relative w-[100%] sm:w-[600px] tracking-wide"
      >
        <div className="flex justify-end absolute right-4 top-4 ">
          <RxCrossCircled
            style={{
              width: "25px",
              height: "25px",
              cursor: "pointer",
              color: "white",
            }}
            onClick={closeCouponModal}
          />
        </div>
        <div className="couponCard">
          <div className="absolute flex flex-col justify-between h-full z-10 -left-2">
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
          </div>
          <div className="absolute flex flex-col justify-between h-full z-10 -right-2">
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
            <div className="p-2 rounded-full bg-[#135779]" />
          </div>
          <div className="flex w-[100%]">
            <div className=" w-[40%] h-[180px]">
              <img
                width={"100%"}
                height={"100%"}
                className="object-cover h-[inherit]"
                src={offerCard}
                alt="offer"
              />
            </div>
            <div className="p-4 w-[60%]  flex flex-col justify-center items-center bg-light-blue">
              <div className="flex items-baseline gap-2 py-3 px-6 w-fit rounded-md bg-[rgba(192,229,247,1)]">
                <span className="text-[rgba(21,130,180,1)] font-semibold text-2xl md:text-3xl">
                  {data?.offerData?.strike_amount}$
                </span>
                <span className="text-[rgba(21,130,180,1)] text-sm font-light line-through">
                  {data?.offerData?.real_amount}$
                </span>
              </div>
              <p className="text-[#154A65] text-xl md:text-2xl font-semibold mt-2">
                {data?.offerData?.title}
              </p>
              <p className="text-[rgba(19,87,121,0.7)] text-sm md:text-base font-medium mt-2">
                {moment(data?.coupendata?.expiry_date)
                  .local()
                  .format("MMMM Do YYYY ") || "--"}
              </p>
            </div>
          </div>
          <div className="relative w-full py-4 bg-[rgba(21,130,180,1)] text-white text-xl md:text-2xl font-semibold border-t-2 border-[rgba(137,209,240,1)] flex justify-between gap-2 items-center border-dashed">
            <span className="ml-14 cursor-pointer" onClick={handleCopy}>
              {copyStatus}
            </span>
            <span className="bg-[rgba(255,255,255,0.08)] p-2 mr-10">
              {data?.coupendata?.code}
            </span>
            <img
              className="absolute -top-[10px] left-10"
              src={scissor}
              alt="scissor"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-center flex-col items-center gap-2">
          <span className="font-semibold text-lg text-white">
            Rate this offer
          </span>
          {israted ? (
            <p className="text-white">You have already rated this order.</p>
          ) : (
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={24}
              activeColor="#ffd700"
              emptyIcon={<img src={emptyStar} />}
              filledIcon={<img src={filledStar} />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CouponModal;
