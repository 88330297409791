import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate } from "react-router-dom";

const PrivateRouting = ({ children }) => {
  const resdata = JSON.parse(localStorage.getItem("SaveOn_Web_Cread"));
  if (!resdata?.apikey) {
    toast.error("Please login to access this page");
    return (
      <>
        <Toaster />
        <Navigate to="/" />
      </>
    );
  }
  return <div>{children}</div>;
};
export default PrivateRouting;
