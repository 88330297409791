import React, { useEffect, useState } from "react";
import ourPartnersback from "../../images/ourPartnersback.png";
import partnersimg from "../../images/amazon.png";
import backIcon from "../../images/back-arrow.png";
import nextIcon from "../../images/right-arrow.png";
import { Link } from "react-router-dom";
import { GetAllPartners } from "../../ReduxToolkit/Slices/Partners/Partners.action";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "../../ReduxToolkit/Apis";
import { LazyLoadImage } from "react-lazy-load-image-component";

const OurPartners = () => {
  let dispatch = useDispatch();
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  useEffect(() => {
    dispatch(GetAllPartners({ count: count }));
    window.scrollTo(0, 0);
  }, [count]);
  let partners = useSelector((store) => store?.Partners);
  const handleNextPage = () => {
    setCount((prevCount) => prevCount + pageSize);
    setPage((prevCount) => prevCount + 1);
  };
  const handlePrevPage = () => {
    if (count >= pageSize) {
      setCount((prevCount) => prevCount - pageSize);
      setPage((prevCount) => prevCount - 1);
    }
  };
  return (
    <div>
      {/* --- Intro Section ---- */}
      <div className="w-full relative m-auto ">
        <img
          alt="offerbackgraundbanner"
          className=" object-cover w-full h-[40vh] md:h-[60vh]"
          src={ourPartnersback}
        />
        <div className="absolute top-0 m-auto w-full   h-full flex">
          <div className="  text-zinc-50 w-full  md:1/2 flex flex-col justify-center align-middle text-center m-auto ">
            <h2 className="font-extrabold text-cxl md:text-c_xl  ">
              Our Partners
            </h2>
            <p className="text-csm  md:text-c_sm  w-[95%] md:w-[50%] lg:w-[30%]  text-center m-auto">
              It is a long established fact that a reader will be distracted by
              the readable content of a.
            </p>
          </div>
        </div>
      </div>

      {/* ----- Display Partners -------------- */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 gap-y-6 md:gap-y-10 lg:gap-y-14 w-[90%] max-w-[1500px] mx-auto pt-8 md:pt-16 pb-8 md:pb-16 box-border">
        {partners?.AllPartners?.map((partner, i) => (
          <Link to={`/ourpartners/${partner?.id}`} key={i}>
            <div className="shadow-[0px_1px_8px_0px_rgba(0,0,0,0.08)] group relative">
              <div className="p-4 sm:p-4 h-44">
                <LazyLoadImage
                  effect="blur"
                  className="h-full w-full object-cover"
                  objectFit="cover"
                  src={
                    partner?.images[0]?.image
                      ? `${BaseUrl}${partner?.images[0]?.image}`
                      : ""
                  }
                  alt="giveaway"
                  height={"100%"}
                  width={"100%"}
                />
              </div>

              <div className="text-center border-t-2 p-2 md:p-4 flex justify-center align-middle bg-light-blue">
                <p className="text-clg md:text-c_lg font-semibold overflow-hidden text-ellipsis whitespace-nowrap w-full">
                  {partner?.name ? partner?.name : "---"}
                </p>
              </div>

              <div className="absolute bottom-0 left-0 w-full border-b-4 border-primary-blue group-hover:border-b-8 duration-200 ease-linear" />
            </div>
          </Link>
        ))}
      </div>

      {partners?.AllPartners?.length > 10 && (
        <div className="flex justify-end w-[90%] max-w-[1500px] mx-auto py-4">
          <div className="flex items-center gap-4">
            <img
              className={`w-8 cursor-pointer border hover:border-[#0383C6] rounded-full p-1 ${
                count < pageSize ? "opacity-50 cursor-not-allowed" : ""
              }`}
              src={backIcon}
              alt="back icon"
              onClick={handlePrevPage}
              disabled={count < pageSize}
            />
            <img
              className={`w-8 cursor-pointer border hover:border-[#0383C6] rounded-full p-1 ${
                partners?.AllPartners?.length < pageSize
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              src={nextIcon}
              alt="next icon"
              onClick={handleNextPage}
              disabled={partners?.AllPartners?.length < pageSize}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OurPartners;
