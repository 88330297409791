 
import { Toaster } from 'react-hot-toast';
import './App.css';
import CombineRouting from './Routes/CombineRouting';
import Header from './components/Header';
import Footer from './components/footer/Footer';
function App() {
  return (
    <div className="App">
            <Toaster />
    <Header/>
    <div className='mt-[73px]'>
   <CombineRouting/>
    </div>
   <Footer/>
    </div>
  );
}

export default App;
