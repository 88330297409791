import { createSlice } from "@reduxjs/toolkit";
import {
  addupdatecheckoutDetails,
  BuytThisPlan,
  CancelSubscription,
  CreateOrderSubscription,
  CreatThisplanOrder,
  GetmyCheckoutDetails,
  GetmyCurrentSubscription,
  GetmyGiveaway,
  GetmyPaymentHistory,
  GetsubscriptionPlans,
  RateOrder,
  RedeemCoupen,
  VerifiySubscription,
} from "./Subscription.action";
let initialState = {
  loading: false,
  error: null,
  planDetails: [],
  userSubscription: [],
  Mygiveaways: [],
  myPaymentHistory: [],
  checkoutDetails: {},
};

const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  extraReducers: (builder) => {
    // --------- Get Subscription Plan ---------
    builder
      .addCase(GetsubscriptionPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetsubscriptionPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.planDetails = action?.payload?.r;
      })
      .addCase(GetsubscriptionPlans.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Create this plan order  ---------
    builder
      .addCase(CreatThisplanOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreatThisplanOrder.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(CreatThisplanOrder.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Create this Subscription Order  ---------
    builder
      .addCase(CreateOrderSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateOrderSubscription.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(CreateOrderSubscription.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Create this Subscription Order  ---------
    builder
      .addCase(VerifiySubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(VerifiySubscription.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(VerifiySubscription.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Cancel this Subscription Order  ---------
    builder
      .addCase(CancelSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(CancelSubscription.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(CancelSubscription.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Create this Subscription Order  ---------
    builder
      .addCase(RedeemCoupen.pending, (state) => {
        state.loading = true;
      })
      .addCase(RedeemCoupen.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(RedeemCoupen.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- get user Subscription by id  ---------
    builder
      .addCase(GetmyCurrentSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetmyCurrentSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.userSubscription = action?.payload?.r;
      })
      .addCase(GetmyCurrentSubscription.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- get user Subscription by id  ---------
    builder
      .addCase(GetmyGiveaway.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetmyGiveaway.fulfilled, (state, action) => {
        state.loading = false;
        state.Mygiveaways = action?.payload?.r;
      })
      .addCase(GetmyGiveaway.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- get user Subscription by id  ---------
    builder
      .addCase(RateOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(RateOrder.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(RateOrder.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- get user Subscription by id  ---------
    builder
      .addCase(GetmyPaymentHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetmyPaymentHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.myPaymentHistory = action?.payload?.r;
      })
      .addCase(GetmyPaymentHistory.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- get user Subscription by id  ---------
    builder
      .addCase(GetmyCheckoutDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetmyCheckoutDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.checkoutDetails = action?.payload?.r;
      })
      .addCase(GetmyCheckoutDetails.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- get user Subscription by id  ---------
    builder
      .addCase(addupdatecheckoutDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(addupdatecheckoutDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addupdatecheckoutDetails.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export default SubscriptionSlice.reducer;
