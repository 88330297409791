import { Link, useLocation } from "react-router-dom";
import Card from "../../components/Card";
import Titlebar from "../../components/Titlebar";
import searchIcon from "../../images/search.svg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetOffersByCategoryid } from "../../ReduxToolkit/Slices/Offers/Offers.action";
import toast from "react-hot-toast";
const AllOffers = () => {
  let location = useLocation()
  const idMatch = location?.pathname.match(/\/alloffers\/(\d+)$/)
  const id = idMatch ? idMatch[1] : null
  let dispatch = useDispatch()
  useEffect(() => {
    dispatch(GetOffersByCategoryid({ Category_id: id, type: 1 }))
    window.scrollTo(0, 0)
  }, [])
  let OfferList = useSelector((store) => store?.Offers)
  const [searchOffer, setsearchOffer] = useState("")
  let HandeldSearchOffers = () => {
    if (!searchOffer.trim()) {
      toast("Please enter a offers to search.");
      return;
    }
    dispatch(
      GetOffersByCategoryid({
        Category_id: id,
        type: 1,
        searchkey: searchOffer,
      })
    )
  }
  let handeldresetbutton=()=>{
    setsearchOffer("")
    dispatch(GetOffersByCategoryid({ Category_id: id, type: 1 }))
  }
  
  return (
    <div>
      {/* --------------- Search Section ----------- */}
      <div className="py-14 w-full bg-bg-color mt-14">
        <div className="w-[90%] max-w-[1500px] mx-auto">
          <div className="flex flex-col md:flex-row gap-3 py-8">
            <div className="relative w-full md:w-[45%]">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 rounded-lg">
                <img
                  src={searchIcon}
                  alt="search icon"
                  className="w-5 h-5 text-gray-400"
                />
              </div>
              <input
                onChange={(e) => setsearchOffer(e.target.value)}
                onClear={() => setsearchOffer("")}
                type="text"
                value={searchOffer}
                placeholder="Search by offers"
                className="w-full h-inh pl-10 py-4 text-sm text-gray-700 placeholder-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-200 border focus:border-[rgba(8,47,73,0.24)]"
              />
            </div>
            <button 
              disabled={!searchOffer.trim() ? true : false}
              onClick={HandeldSearchOffers}
              className=" bg-[rgba(18,104,146,1)] w-[40%] md:w-auto md:px-10 py-3 rounded-lg text-white"
            >
              Search
            </button>
            
            {searchOffer.trim()?  <button 
              disabled={!searchOffer.trim() ? true : false}
              onClick={handeldresetbutton}
              className=" bg-[rgba(18,104,146,1)] w-[40%] md:w-auto md:px-10 py-3 rounded-lg text-white"
            >
              reset
            </button>:""}
           
            
          </div>
        </div>
      </div>
      {/* --------------- Dinining Content ----------- */}
      <div className="w-[90%] m-auto pt-14 ">
        <Titlebar title={OfferList?.OffersListByCategoryId[0]?.offer_category}  />
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 gap-y-6 md:gap-y-10 lg:gap-y-14   m-auto  md:pt-8 pb-8 md:pb-16 box-border">
          {OfferList?.OffersListByCategoryId?.map((offer, i) => (
            <Link key={offer.id} to={`/offers/${offer?.id}`}>
              <Card data={offer} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllOffers;
