import React from "react";
const SectionTitle = ({ title }) => {
  return (
    <div className="w-full flex justify-between items-center ">
      <h2 className="font-bold text-cx lg:text-c_x transition-colors duration-300 group-hover:text-blue-700">
        {title}
      </h2>
    </div>
  );
};

export default SectionTitle;
