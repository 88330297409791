import { createSlice } from "@reduxjs/toolkit";
import { GetHomeBannerImages, GetHomepageCategory, GetOffersByCategoryid, GetOffersByPartnerId, GetOffersDetailsByCategoryId } from "./Offers.action";
let initialState = {
  loading: false,
  error: null,
  HomepageCategorys: [],
  HomeBannerImages:[],
  OffersListByCategoryId:[],
  OfferDetailsById:{},
  OffersByPartnerId:[]
};

const OfferSlice = createSlice({
  name: "offers",
  initialState,
  extraReducers: (builder) => {
    // --------- Get Homepage Category ---------
    builder
      .addCase(GetHomepageCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetHomepageCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.HomepageCategorys = action?.payload?.r;
      })
      .addCase(GetHomepageCategory.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get Homepage banners ---------
    builder
      .addCase(GetHomeBannerImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetHomeBannerImages.fulfilled, (state, action) => {
        state.loading = false;
        state.HomeBannerImages = action?.payload?.r;
      })
      .addCase(GetHomeBannerImages.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get Homepage banners ---------
    builder
      .addCase(GetOffersByCategoryid.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetOffersByCategoryid.fulfilled, (state, action) => {
        state.loading = false;
        state.OffersListByCategoryId = action?.payload?.r;
      })
      .addCase(GetOffersByCategoryid.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
 
    // --------- Get Homepage banners ---------
    builder
      .addCase(GetOffersDetailsByCategoryId.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetOffersDetailsByCategoryId.fulfilled, (state, action) => {
        state.loading = false;
        state.OfferDetailsById = action?.payload?.r;
      })
      .addCase(GetOffersDetailsByCategoryId.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
 
    // --------- Get Offers By Partner ---------
    builder
      .addCase(GetOffersByPartnerId.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetOffersByPartnerId.fulfilled, (state, action) => {
        state.loading = false;
        state.OffersByPartnerId = action?.payload?.r;
      })
      .addCase(GetOffersByPartnerId.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
 
  },
});

export default OfferSlice.reducer;
