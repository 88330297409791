import { createSlice } from "@reduxjs/toolkit";
import { GetAboutusDetails, GetFaqsListData, Getgiveaways, GetOneOffplans, GetUserCoupens } from "./Deals.action";
let initialState = {
  loading: false,
  error: null,
  giveaways:[],
  aboutus:{},
  UserCoupens:[],
  UserOneOfPlans:[],
  FaqsList:[]
};

const DealsSlice = createSlice({
  name: "deals",
  initialState,
  extraReducers: (builder) => {
    // --------- Get Giveaways  Category ---------
    
      builder
      .addCase(Getgiveaways.pending, (state) => {
        state.loading = true;
      })
      .addCase(Getgiveaways.fulfilled, (state, action) => {
        state.loading = false;
        state.giveaways = action?.payload?.r;
      })
      .addCase(Getgiveaways.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get About us ---------
      builder
      .addCase(GetAboutusDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAboutusDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.aboutus = action?.payload?.r[0];
      })
      .addCase(GetAboutusDetails.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get Coupens us ---------
      builder
      .addCase(GetUserCoupens.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetUserCoupens.fulfilled, (state, action) => {
        state.loading = false;
        state.UserCoupens = action?.payload?.r;
      })
      .addCase(GetUserCoupens.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get One of plans ---------
      builder
      .addCase(GetOneOffplans.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetOneOffplans.fulfilled, (state, action) => {
        state.loading = false;
        state.UserOneOfPlans = action?.payload?.r;
      })
      .addCase(GetOneOffplans.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get One of plans ---------
      builder
      .addCase(GetFaqsListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetFaqsListData.fulfilled, (state, action) => {
        state.loading = false;
        state.FaqsList = action?.payload?.r;
      })
      .addCase(GetFaqsListData.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
   
  },
})

export default DealsSlice.reducer;
