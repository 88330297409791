import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import showPass from "../../images/eye.svg";
import hidePass from "../../images/eyeTwo.svg";
import tag from "../../images/tag.svg";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "../../ReduxToolkit/Slices/Auth/Auth.action";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { RxCrossCircled } from "react-icons/rx";

const LoginModal = ({
  closeLoginModal,
  switchToSignup,
  switchToForgotPass,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [rememberedEmail, setRememberedEmail] = useState("");
  const modalRef = useRef(null);
  let dispatch = useDispatch();
  let Auth = useSelector((store) => store?.Auth);
  let [authload, setauthload] = useState(false);
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: rememberedEmail || "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required !"),
      password: Yup.string()
        .required("Password is required !")
        .min(8, "Password should contain at least 8 characters")
        .max(20, "Password must contain at most 20 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[\W_]/,
          "Password must contain at least one special character (e.g., !, @, #, $, etc.)"
        ),
    }),
    onSubmit: (values) => {
      let logindata = new FormData();
      logindata.append("email", values?.email);
      logindata.append("password", values?.password);
      setauthload(true);
      dispatch(Login(logindata))
        .unwrap()
        .then((result) => {
          if (result?.s == 1 && result?.r?.token) {
            setauthload(false);
            localStorage.setItem("SaveOn_Web_Cread", JSON.stringify(result?.r));
            navigate("/");
            toast(`${result?.m} Welcome Back to SaveOn !`, {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
                textAlign: "center",
              },
            });
            if (rememberMe) {
              localStorage.setItem("rememberedEmail", values.email);
            } else {
              localStorage.removeItem("rememberedEmail");
            }

            closeLoginModal();
          } else {
            setauthload(false);
            toast.error(result?.m, {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        });
    },
  });
  const handleshowPass = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeLoginModal();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeLoginModal]);

  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    if (savedEmail) {
      setRememberedEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  return (
    <div className="fixed z-50 inset-0 bg-[rgba(8,47,73,0.84)] flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-white rounded-[32px] p-8 relative w-[90%] sm:w-[500px] tracking-wide border-dashed border-2 border-[rgba(14,47,67,0.5)] shadow-[10px_8px_0_0_rgba(255,255,255,0.42)]"
      >
        <div className="text-2xl text-white absolute -top-14 left-24 flex gap-1">
          <span className="text-[#60C2E9]">Login</span> to get exclusive offers{" "}
          <img src={tag} width={25} alt="tag" />
        </div>
        <div className="flex justify-end absolute right-8 top-6 ">
          <RxCrossCircled
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={closeLoginModal}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6 relative">
            <label htmlFor="email" className="text-left text-[#082F49D1] mb-2">
              Email Address
            </label>
            <input
              className="border border-gray-300 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
              type="email"
              name="email"
              id="email"
              placeholder="Enter email address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ background: "rgba(241,249,254,1)" }}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="mt-6 relative">
            <label
              htmlFor="password"
              className="text-left text-[#082F49D1] mb-2"
            >
              Password
            </label>
            <input
              className="border border-gray-300 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Enter your password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ background: "rgba(241,249,254,1)" }}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500 text-sm">
                {formik.errors.password}
              </div>
            ) : null}
            <img
              onClick={handleshowPass}
              className="absolute top-[45px] right-4 cursor-pointer"
              src={showPassword ? hidePass : showPass}
              alt="show password icon"
            />
          </div>

          <div className="mt-4 flex justify-between">
            <div className="flex items-center gap-2">
              <input
                className="form-checkbox h-4 w-4 border-2 border-blue-500 focus:border-blue-700 cursor-pointer"
                type="checkbox"
                name="rememberMe"
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label
                className="text-[#135779] text-sm font-semibold cursor-pointer select-none"
                htmlFor="rememberMe"
              >
                Remember Me
              </label>
            </div>
            <div>
              <p
                onClick={switchToForgotPass}
                className="underline text-[#135779] text-sm font-semibold select-none cursor-pointer"
              >
                Forgot Password?
              </p>
            </div>
          </div>

          <div className="mt-4 flex justify-between items-center">
            <div>
              <p
                className="text-[#135779] font-semibold"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                Don’t have account?
                <span
                  onClick={switchToSignup}
                  className="text-[#23A1D4]  cursor-pointer"
                >
                  {" "}
                  Sign Up
                </span>
              </p>
            </div>
            <div>
              <button
                disabled={authload}
                className="bg-[#23A1D4] py-2 px-6 text-[#FFF] rounded-lg"
                type="submit"
              >
                {authload ? "Loading..." : "Login"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;
