import React, { useEffect, useState } from "react";
import Slider from "../components/Slider";
import Titlebar from "../components/Titlebar";
import Partners from "../components/partners/Partners";
import Card from "../components/Card";
import Download from "../components/downloadapp/Download";
import Howto from "../components/howtouse/Howto";
import Plans from "../components/plans/Plans";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetHomeBannerImages,
  GetHomepageCategory,
} from "../ReduxToolkit/Slices/Offers/Offers.action";
import CardSkeleton from "../components/CardSkeleton";
import CongratsModal from "../components/modal/CongratsModal";
import { VerifiySubscription } from "../ReduxToolkit/Slices/Subscription/Subscription.action";
import "../components/Animationcss.css";
const Home = () => {
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(GetHomepageCategory());
    dispatch(GetHomeBannerImages());
    window.scrollTo(0, 0);
  }, [dispatch]);

  let [verifiyplandata, setverifiyplandata] = useState(null);
  const Offer = useSelector((store) => store?.Offers);
  let [Verifiysubloader, setVerifiysubloader] = useState(false);

  useEffect(() => {
    const callAPI = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get("token");
        const type = urlParams.get("type");
        console.log(token);
        if (token) {
          setVerifiysubloader(true);
          let SubscriptionData = new FormData();
          SubscriptionData?.append("id", token);
          SubscriptionData?.append("type", type);
          await dispatch(VerifiySubscription(SubscriptionData))
            .unwrap()
            .then((result) => {
              if (result?.r?.status === "APPROVED") {
                setVerifiysubloader(false);
                setShowCongratsModal(true);
                setverifiyplandata(result?.r);
                urlParams.delete("token");
                urlParams.delete("type");
                urlParams.delete("PayerID");
                navigate({ search: urlParams.toString() }, { replace: true });
              } else {
                setVerifiysubloader(false);
                setShowCongratsModal(true);
                urlParams.delete("token");
                urlParams.delete("type");
                urlParams.delete("PayerID");
                navigate({ search: urlParams.toString() }, { replace: true });
              }
            });
        }
      } catch (error) {
        console.error("Error calling API:", error);
      }
    };

    callAPI();
  }, [location.search, dispatch, navigate]);

  return (
    <main className="">
      {Verifiysubloader && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center  bg-black bg-opacity-50">
          <span className="loader"></span>
        </div>
      )}
      <div className="h-[calc(100vh-72px)] mt-[72px] bg-slate-600 mb-14 ">
        <Slider data={Offer?.HomeBannerImages} />
      </div>
      {Offer?.loading ? (
        <div>Getting Data</div>
      ) : (
        <div>
          {Offer?.HomepageCategorys?.map((category, i) => (
            <section key={i} className="h-full bg-white w-full ">
              <div className="w-[90%] mx-auto">
                {category?.offers?.length >= 4 ? (
                  <Titlebar
                    title={`${category?.name}`}
                    route={`/offers/alloffers/${category?.id}`}
                  />
                ) : (
                  <Titlebar
                    title={`${category?.name}`}
                    data={category?.offers}
                  />
                )}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 gap-y-6 md:gap-y-10 lg:gap-y-14 m-auto pt-8 md:pt-8 pb-8 md:pb-16 box-border">
                  {category?.offers?.slice(0, 3).map((offer) => (
                    <Link key={offer.id} to={`offers/${offer?.id}`}>
                      {Offer?.loading ? (
                        <CardSkeleton />
                      ) : (
                        <Card data={offer} />
                      )}
                    </Link>
                  ))}
                </div>
              </div>
            </section>
          ))}
        </div>
      )}
      {/* OUR PARTNERS SECTION */}
      <Partners />
      {/* DOWNLOAD SECTION */}
      <Download />
      {/* HOW TO USE SECTION */}
      <Howto />
      {/* ON-OFF PLANS SECTION */}
      <Plans />
      <CongratsModal
        data={verifiyplandata && verifiyplandata}
        setShowCongratsModal={setShowCongratsModal}
        showCongratsModal={showCongratsModal}
      />
    </main>
  );
};

export default Home;
