import React, { useEffect } from "react";
import productdetailsbannerimage from "../../images/productdetailsbannerimage.png";
import partnersimg from "../../images/amazon.png";
import locationlogo from "../../images/location.svg";
import phonelogo from "../../images/phone.svg";
import gmailLogo from "../../images/gmail.svg";
import clocklogo from "../../images/clock.svg";
import Card from "../../components/Card";
import PlanCard from "../../components/PlanCard";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetPartnerById } from "../../ReduxToolkit/Slices/Partners/Partners.action";
import { formatTime } from "../../Funtions/CommonFunc";
import { BaseUrl } from "../../ReduxToolkit/Apis";
import { GetOffersByPartnerId } from "../../ReduxToolkit/Slices/Offers/Offers.action";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import staticimage from "../../images/Logo.svg";
import Plans from "../../components/plans/Plans";

const PartnerDetails = () => {
  let location = useLocation();
  const idMatch = location?.pathname.match(/\/ourpartners\/(\d+)$/);
  const id = idMatch ? idMatch[1] : null;
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetPartnerById(id));
    dispatch(GetOffersByPartnerId(id));
    window.scrollTo(0, 0);
  }, []);

  let partnerData = useSelector((store) => store?.Partners);
  let offersData = useSelector((store) => store?.Offers);

  return (
    <div>
      {/* --- Intro Section ---- */}
      <div className="w-full relative m-auto">
        <LazyLoadImage
          effect="blur"
          alt="offerbackgraundbanner"
          className="object-cover object-center w-full h-[40vh] md:h-[75vh]"
          src={
            partnerData?.PartnerDetailsById?.images
              ? `${BaseUrl}${partnerData?.PartnerDetailsById?.images[0]?.image}`
              : ""
          }
          height="100%"
          width={"100%"}
        />
      </div>

      {/* --- Partner details content Section ---- */}
      <div className="flex flex-col lg:flex-row w-full">
        <div className="bg-slate-50 flex flex-col lg:flex-row w-full lg:w-3/5 justify-center gap-10 p-8 lg:p-16">
          <div className="w-full flex flex-col sm:flex-row justify-start lg:justify-center items-center gap-6 md:gap-14">
            <img
              src={
                partnerData?.PartnerDetailsById?.images
                  ? `${BaseUrl}${partnerData?.PartnerDetailsById?.images[0]?.image}`
                  : ""
              }
              alt="partner_Logo"
              className="w-[120px] md:w-[200px] lg:w-[150px] xl:w-[226px] h-44 object-cover"
            />
            <div className="flex flex-col justify-start gap-2 w-full md:w-1/2">
              <h2 className="text-cx lg:text-cx xl:text-c_x text-dark-blue">
                {partnerData?.PartnerDetailsById?.name
                  ? partnerData?.PartnerDetailsById?.name
                  : "------"}
              </h2>
              <p className="text-csm lg:text-c_sm">
                {partnerData?.PartnerDetailsById?.description
                  ? partnerData?.PartnerDetailsById?.description
                  : "------"}
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[rgba(227,242,251,1)] flex w-full lg:w-2/5 flex-col gap-4 p-8 lg:p-16">
          <p className="text-c_md text-primary-blue"> Address</p>
          <div className="flex gap-2 items-start">
            <img
              src={locationlogo}
              alt="location logo"
              className="w-[40px] h-[40px]"
            />
            <p className="text-csm lg:text-c_sm text-dark-blue">
              {partnerData?.PartnerDetailsById?.address
                ? partnerData?.PartnerDetailsById?.address
                : "------"}
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex gap-2 items-center">
              <img
                src={phonelogo}
                alt="phone logo"
                className="w-[40px] h-[40px]"
              />
              <p className="text-csm lg:text-c_sm text-dark-blue">
                +
                {partnerData?.PartnerDetailsById?.phno_cc
                  ? partnerData?.PartnerDetailsById?.phno_cc
                  : "------"}{" "}
                <span>
                  {partnerData?.PartnerDetailsById?.phno
                    ? partnerData?.PartnerDetailsById?.phno
                    : "------"}
                </span>
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <img
                src={gmailLogo}
                alt="gmail logo"
                className="w-[40px] h-[40px]"
              />
              <p className="text-csm lg:text-c_sm text-dark-blue">
                {partnerData?.PartnerDetailsById?.email
                  ? partnerData?.PartnerDetailsById?.email
                  : "------"}
              </p>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <img
              src={clocklogo}
              alt="clock logo"
              className="w-[40px] h-[40px]"
            />
            <p className="text-csm lg:text-c_sm text-dark-blue">
              <span>
                {moment(
                  partnerData?.PartnerDetailsById?.start_time,
                  "HH:mm"
                ).format("hh:mm A")}{" "}
                -{" "}
                {moment(
                  partnerData?.PartnerDetailsById?.end_time,
                  "HH:mm"
                ).format("hh:mm A")}
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* ----------- Coupons Listing --------------  */}
      <div className="w-[90%] max-w-[1500px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 gap-y-6 md:gap-y-10 lg:gap-y-14 m-auto pt-8 md:pt-16 pb-8 md:pb-16 box-border">
        {offersData?.OffersByPartnerId?.map((offerdata, i) => (
          <div key={i}>
            <Link key={offerdata.id} to={`/offers/${offerdata?.id}`}>
              <Card data={offerdata} />
            </Link>
          </div>
        ))}
      </div>

      {/* ----------- One-off Plans --------------  */}
      <div className="py-8 md:py-16 m-auto border-2 bg-[rgba(243,243,243,1)]">
        <div className="w-[90%] max-w-[1500px] mx-auto">
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14 lg:gap-20 md:gap-14 pt-14"> */}
          <Plans />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default PartnerDetails;
