import React from "react";
import Marquee from "react-fast-marquee";
import amazonLogo from "../images/amazon.png";
import flipkartLogo from "../images/flipkart.png";
import swiggyLogo from "../images/swiggy.png";
import { BaseUrl } from "../ReduxToolkit/Apis";
import { LazyLoadImage } from "react-lazy-load-image-component";
import usericonplaceholder from "../images/usericon.png"
import 'react-lazy-load-image-component/src/effects/blur.css';
const Marque = ({data,loading}) => {
  return (
    <Marquee gradient direction={"left"} className="flex items-center gap-4">
      {data?.map((partner)=>
      <div className="border-[#6E818D24] py-2 px-6 h-[70px] xl:h-[120px] mx-2 md:mx-4 border bg-[#FAFAFA] flex items-center justify-center rounded-[15px]">
        <LazyLoadImage
        alt="amazonLogo"
        placeholderSrc={usericonplaceholder}
        height={"100%"}
        width={"100%"}
        effect="blur"
          className="w-[100%] h-[100%] md:w-[100px] xl:w-[150px] object-cover" 
          src={`${BaseUrl}${partner?.images[0]?.image? partner?.images[0]?.image: ""}`}
        />
      </div>
      )}
    </Marquee>
  );
};

export default Marque;
